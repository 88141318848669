<template>
    <div>
        <b-card no-body>
            <b-card-header class="p-0">
            </b-card-header>
            <b-card-body class="px-0">
                <vue-good-table
                    :columns="columns[!this.userId ? +type - 1 : 2 + +type]"
                    :rows="activeTableInvoice"
                    :rtl="false"
                    :small="true"
                    styleClass="vgt-table condensed"
                    ref="accounts-table"
                >
                 <template
                    slot="table-row"
                    slot-scope="props"
                >
                
                    <span v-if="props.column.field === 'lastMatchDate' ">
                        {{ !!props.row.lastMatchDate
                            ? new Date(props.row.lastMatchDate).toISOString().substr(0, 10)
                            : "لا يوجد" }}
                    </span>
                    <span v-else-if="props.column.field === 'invoiceDate' ">
                        {{ !!props.row.invoiceDate
                            ? new Date(props.row.invoiceDate).toISOString().substr(0, 10)
                            : "لا يوجد" }}
                        
                    </span>
                    <span v-else-if="props.column.field === 'action'">
                        <b-button
                            @click="goToDetails(props.index)"
                            size="sm"
                            variant="flat-secondary"
                            class="btn-icon rounded-circle"
                            style="padding: 2px 6px!important"
                        >
                            <unicon name="ellipsis-v" width="18"></unicon>
                        </b-button>
                    </span>

                    <!-- Column: Common -->
                    <span v-else>
                        {{ props.formattedRow[props.column.field] }}
                    </span>
                </template>
                </vue-good-table>
            </b-card-body>
        </b-card>
        <b-col cols="12" class="d-flex justify-content-center">
            <b-pagination
                :value="invoiceFirstPage"
                :total-rows="mainInvoiceList.length"
                :per-page="invoicePageLength"
                first-number
                last-number
                prev-class="prev-item"
                next-class="next-item"
                class="mt-1 mb-0"
                @input="value => updateInvoicePagination(value)"
            >
                <template #prev-text>
                    <unicon width="20" name="angle-right" fill="royalblue" />
                </template>
                <template #next-text>
                    <unicon width="20" name="angle-right" fill="royalblue" />
                </template>
            </b-pagination>
        </b-col>
    </div>
</template>

<script>
import {
  BPagination,
  BButton,
  BCard,
  BCardHeader,
  BCardBody,
  BCol,
} from 'bootstrap-vue'
import { VueGoodTable } from 'vue-good-table'
import store from '@/store'
import { mapActions, mapGetters } from 'vuex'

export default {
    components: {
        VueGoodTable,
        BPagination,
        BButton,
        BCard,
        BCardHeader,
        BCardBody,
        BCol,
    },
    props: {
        type: String,
        userId: String
    },
    data: () => ({
        isNotSelectedRows: true,
        columns: [
            [
                {
                    label: 'اسم الأستاذ',
                    field: 'userName',
                },
                {
                    label: 'عدد الأشتراكات',
                    field: 'codeCount',
                },
                {
                    label: 'تاريخ آخر مطابقة',
                    field: 'lastMatchDate',
                    sortable: false
                },
                {
                    label: 'صافي الأرباح',
                    field: 'netProfit',
                },
                {
                    label: 'الرصيد المستحق له',
                    field: 'balanceDue',
                },
                {
                    label: 'تفاصيل',
                    field: 'action',
                    sortable: false
                },
            ],
            [
                {
                    label: 'اسم المجيب',
                    field: 'userName',
                },
                {
                    label: 'عدد الرموز',
                    field: 'codeCount',
                },
                {
                    label: 'تاريخ آخر مطابقة',
                    field: 'lastMatchDate',
                    sortable: false
                },
                {
                    label: 'صافي الأرباح',
                    field: 'netProfit',
                },
                {
                    label: 'الرصيد المستحق له',
                    field: 'balanceDue',
                },
                {
                    label: 'تفاصيل',
                    field: 'action',
                    sortable: false
                },
            ],
            [
                {
                    label: 'نقطة البيع',
                    field: 'userName',
                },
                {
                    label: 'عدد الرموز',
                    field: 'codeCount',
                },
                {
                    label: 'تاريخ آخر مطابقة',
                    field: 'lastMatchDate',
                    sortable: false
                },
                {
                    label: 'صافي الأرباح',
                    field: 'netProfit',
                },
                {
                    label: 'الرصيد المستحق لنا',
                    field: 'balanceDue',
                },
                {
                    label: 'تفاصيل',
                    field: 'action',
                    sortable: false
                },
            ],
            
            [
                {
                    label: 'تاريخ',
                    field: 'invoiceDate',
                    sortable: false
                },
                {
                    label: 'رقم الفاتورة',
                    field: 'invoiceNumber',
                },
                {
                    label: 'عدد الرموز',
                    field: 'codeCount',
                },
                {
                    label: 'قيمة الفاتورة',
                    field: 'invoiceValue',
                },
                {
                    label: 'تفاصيل',
                    field: 'action',
                    sortable: false
                },
            ],
            [
                {
                    label: 'تاريخ',
                    field: 'invoiceDate',
                    sortable: false
                },
                {
                    label: 'رقم الفاتورة',
                    field: 'invoiceNumber',
                },
                {
                    label: 'عدد الرموز',
                    field: 'codeCount',
                },
                {
                    label: 'قيمة الفاتورة',
                    field: 'invoiceValue',
                },
                {
                    label: 'تفاصيل',
                    field: 'action',
                    sortable: false
                },
            ],
            [
                {
                    label: 'تاريخ',
                    field: 'invoiceDate',
                    sortable: false
                },
                {
                    label: 'رقم الفاتورة',
                    field: 'invoiceNumber',
                },
                {
                    label: 'عدد الإشتراكات',
                    field: 'codeCount',
                },
                {
                    label: 'قيمة الفاتورة',
                    field: 'invoiceValue',
                },
                {
                    label: 'تفاصيل',
                    field: 'action',
                    sortable: false
                },
            ],
        ],
    }),
    created() {
        if(this.type <= 3 && this.type > 0) {
            this.getInvoice(this.type)
            if (!this.userId) {
                store.commit('app/UPDATE_CREATE_BUTTON_AND_GLOBALE_SEARCH', {
                    isActive: true,
                    isbuttonActive: false,
                    text: '',
                    fetchingFunction: null,
                    placeHolder: 'ابحث عن اسم',
                    value: '',
                    filterFunc: this.filterInvoice
                })
            } else {
                this.getUserInvoice(this.userId)
                store.commit('app/UPDATE_CREATE_BUTTON_AND_GLOBALE_SEARCH', {
                    isActive: true,
                    text: 'فاتورة جديدة',
                    fetchingFunction: this.createInvoce,
                    placeHolder: 'ابحث عن فاتورة',
                    value: '',
                    filterFunc: this.filterInvoice
                })
            }
        } else {
            this.$router.push('/error-404')
        }
    },
    computed: {
        ...mapGetters(['mainInvoiceList', 'activeTableInvoice', 'invoicePageLength', 'invoiceFirstPage'])
    },
    methods: {
        ...mapActions(['getInvoice', 'createInvoce', 'getUserInvoice', 'updateInvoicePagination', 'filterInvoice']),
        goToDetails(index) {
            const id = this.activeTableInvoice[index].id
            this.$router.push(`/invoice/${this.type}/${!this.userId ? id : this.userId + '/' + id +'/details'}`)
        }
    },
    watch: {
        type(){
            if(this.type <= 3 && this.type > 0) {
                this.getInvoice(this.type)
                if (!this.userId) {
                    store.commit('app/UPDATE_CREATE_BUTTON_AND_GLOBALE_SEARCH', {
                        isActive: true,
                        isbuttonActive: false,
                        text: '',
                        fetchingFunction: null,
                        placeHolder: 'ابحث عن مستخدم محدد',
                        value: '',
                        filterFunc: this.filterInvoice
                    })
                } else {
                    this.getUserInvoice(this.userId)
                    store.commit('app/UPDATE_CREATE_BUTTON_AND_GLOBALE_SEARCH', {
                        isActive: true,
                        text: 'فاتورة جديدة',
                        fetchingFunction: this.createInvoce,
                        placeHolder: 'ابحث عن مستخدم محدد',
                        value: '',
                        filterFunc: this.filterInvoice
                    })
                }
            } else {
                this.$router.push('/error-404')
            }
        },
        userId() {
            if(this.type <= 3 && this.type > 0) {
                this.getInvoice(this.type)
                if (!this.userId) {
                    store.commit('app/UPDATE_CREATE_BUTTON_AND_GLOBALE_SEARCH', {
                        isActive: true,
                        isbuttonActive: false,
                        text: '',
                        fetchingFunction: null,
                        placeHolder: 'ابحث عن مستخدم محدد',
                        value: '',
                        filterFunc: this.filterInvoice
                    })
                } else {
                    this.getUserInvoice(this.userId)
                    store.commit('app/UPDATE_CREATE_BUTTON_AND_GLOBALE_SEARCH', {
                        isActive: true,
                        text: 'فاتورة جديدة',
                        fetchingFunction: this.createInvoce,
                        placeHolder: 'ابحث عن مستخدم محدد',
                        value: '',
                        filterFunc: this.filterInvoice
                    })
                }
            } else {
                this.$router.push('/error-404')
            }
        }
    }
};
</script>
